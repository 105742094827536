import "./Profile.css"
import Button from "../button/Button";
import Text from "./Text";

const Profile = () => {
    return (

        <div className="first-page">
            <Text />
            <Button />
        </div>

    );
}

export default Profile;