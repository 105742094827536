import "./NavBar.css"
const NavBar = () => {
    return (
        <nav>
            <h1 className="myName">RaviS.</h1>
            <div className="menu">
                <ul>
                    <li>Home </li>
                    <li>About Me</li>
                    <li>Projects</li>
                    <li>Contact</li>
                </ul>
            </div>
        </nav>
    )
}

export default NavBar;