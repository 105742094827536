import "./Text.css"
const Text = () => {
    return (
        <>
            <p className="hello-text">Hello, I am</p>
            <h1 className="name">Ravi Shrestha</h1>
            <p className="job-title-text">I am a Backend Developer</p>
        </>
    )
}

export default Text;