import NavBar from "./components/nav/NavBar";
import Profile from "./components/Profile/Profile";
import Social from "./components/socials/Social";
function App() {
  return (
    <>
      <NavBar />
      <Profile />
      <Social />
    </>
  );
}

export default App;
