import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"
import "./Social.css"
const Social = () => {
    return (
        <div className="social">
            <a target="_blank" href="https://twitter.com/RaviShr123"> <FontAwesomeIcon className="icon twitter" icon={faTwitter} /> </a>
            <a target="_blank" href=""> <FontAwesomeIcon className="icon facebook" icon={faFacebook} /></a>
            <a target="_blank" href="https://github.com/Ivar246"> <FontAwesomeIcon className="icon github" icon={faGithub} /></a>
            <a target="_blank" href=""> <FontAwesomeIcon className="icon  instagram" icon={faInstagram} /></a>
        </div>

    )
}

export default Social;